// extracted by css-extract-rspack-plugin
var _1 = "kv";
var _2 = "lv";
var _3 = "By";
var _4 = "Gy";
var _5 = "Xka";
var _6 = "cla";
var _7 = "Yka";
var _8 = "Aa";
var _9 = "M";
var _a = "ya";
var _b = "za";
var _c = "f";
var _d = "ila";
var _e = "jla";
var _f = "OW";
var _10 = "LT";
var _11 = "MT";
var _12 = "OK";
var _13 = "qE";
var _14 = "ela";
var _15 = "Zka";
var _16 = "ala";
var _17 = "fla";
export { _1 as "alignSelfBaseline", _2 as "alignSelfCenter", _3 as "alignSelfFlexEnd", _4 as "alignSelfFlexStart", _5 as "button", _6 as "destructive", _7 as "disabled", _8 as "disabledDestructive", _9 as "disabledGhost", _a as "disabledPrimary", _b as "disabledSecondary", _c as "ghost", _d as "icon", _e as "loading", _f as "margin", _10 as "marginBottom", _11 as "marginLeft", _12 as "marginRight", _13 as "marginTop", _14 as "normal", _15 as "primary", _16 as "secondary", _17 as "small" }
