import * as React from "react";

import { FormGroup, SelectField } from "@bokio/elements/Form";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getVATReportingPeriodOptions, getVatSettingTitle } from "@bokio/shared/utils/companyInfoUtils";

import {
	getAccountingTypeOptions,
	getAccountingTypeSelectLabelText,
	getVisibleAccountingTypeOptions,
} from "./vatSettingAccountingTypeInputHelpers";

import type { VatSettingAccountingTypeInputState } from "./vatSettingAccountingTypeInputHelpers";
import type { FiscalYearValidator } from "@bokio/settings/src/scenes/FiscalYears/fiscalYearValidator";
import type { ValidatorResult } from "@bokio/shared/validation/entityValidator";

import VatSetting = m.Entities.VatSetting;
import AccountingType = m.Entities.AccountingType;
export type VatSettingAccountingTypeInputProps = {
	state: VatSettingAccountingTypeInputState;
	company: m.Core.CompanyInfo;
	onStateChange: (state: VatSettingAccountingTypeInputState) => void;
	vatSettingDescription?: React.ReactNode;
	accountingTypeDescription?: React.ReactNode;
	validator?: FiscalYearValidator;
	validation?: ValidatorResult;
};

const VatSettingAccountingTypeInput = ({
	company,
	state,
	onStateChange,
	accountingTypeDescription,
	vatSettingDescription,
	validator,
	validation,
}: VatSettingAccountingTypeInputProps) => {
	const onVatSettingChange = (vatSetting: VatSetting) => {
		// Be sure to select an existing accounting type when vat setting is changed
		const visibleAccountingTypeOptions = getVisibleAccountingTypeOptions(
			company.Country,
			company.CompanyType,
			vatSetting,
			company.CountryMetadata.RulesByCompanyTypeAndVatSetting,
		);
		const existingAccountingTypeExistsInSelect = visibleAccountingTypeOptions.some(
			o => o.value === state.accountingType,
		);
		onStateChange({
			vatSetting: vatSetting,
			accountingType: existingAccountingTypeExistsInSelect
				? state.accountingType
				: AccountingType[visibleAccountingTypeOptions[0].value],
		});
	};

	return (
		<React.Fragment>
			<FormGroup>
				<SelectField
					testId="InitialFiscalYearInput_VatSetting_Select"
					label={getVatSettingTitle()}
					value={state.vatSetting}
					onChange={onVatSettingChange}
					options={getVATReportingPeriodOptions(company.CountryMetadata.VatReportingPeriods)}
					errors={validator && validation && validator.filterByField("VatSetting", validation.errors)}
				/>
				{vatSettingDescription}
			</FormGroup>

			<FormGroup
				hidden={
					getVisibleAccountingTypeOptions(
						company.Country,
						company.CompanyType,
						state.vatSetting,
						company.CountryMetadata.RulesByCompanyTypeAndVatSetting,
					).length <= 1
				}
			>
				<SelectField
					label={getAccountingTypeSelectLabelText(company.Country, company.CompanyType, state.vatSetting)}
					testId="InitialFiscalYearInput_AccountingType_Select"
					labelTestId="InitialFiscalYearInput_AccountingType_Label"
					value={state.accountingType}
					onChange={val => onStateChange({ ...state, accountingType: val })}
					options={getAccountingTypeOptions(
						company.Country,
						company.CompanyType,
						state.vatSetting,
						company.CountryMetadata.RulesByCompanyTypeAndVatSetting,
					)}
					errors={validator && validation && validator.filterByField("AccountingForm", validation.errors)}
				/>
				{accountingTypeDescription}
			</FormGroup>
		</React.Fragment>
	);
};

export default VatSettingAccountingTypeInput;
