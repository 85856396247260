import * as React from "react";

import { scrollAreaContext } from "./ScrollAreaContext";

interface ScrollAreaProps {
	className: string;
	touchMoveEnabled: boolean;
}

export const ScrollArea: React.FC<React.PropsWithChildren<ScrollAreaProps>> = ({
	className,
	touchMoveEnabled,
	children,
}) => {
	const scrollDivRef = React.useRef<HTMLDivElement>(null);

	const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
		if (touchMoveEnabled) {
			return true;
		}
		e.preventDefault();
		return undefined;
	};

	const touchAction = touchMoveEnabled ? "auto" : "none";

	const scrollToTop = React.useCallback(() => {
		scrollDivRef.current?.scrollTo({ top: 0 });
	}, []);

	return (
		<scrollAreaContext.Provider
			value={{
				scrollToTop,
			}}
		>
			<div
				ref={scrollDivRef}
				data-scroll="true"
				className={className}
				style={{ touchAction }}
				onTouchMove={onTouchMove}
			>
				{children}
			</div>
		</scrollAreaContext.Provider>
	);
};
