// extracted by css-extract-rspack-plugin
var _1 = "gb";
var _2 = "Vc";
var _3 = "ji";
var _4 = "fi";
var _5 = "ep";
var _6 = "hi";
var _7 = "ii";
var _8 = "gi";
var _9 = "Xc";
export { _1 as "content", _2 as "contentFooter", _3 as "epilogue", _4 as "header", _5 as "illustration", _6 as "indicator", _7 as "preamble", _8 as "signUp", _9 as "smallerMarginHeader" }
