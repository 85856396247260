// extracted by css-extract-rspack-plugin
var _1 = "Ce";
var _2 = "Hq";
var _3 = "qh zq";
var _4 = "Gq Eq Fq";
var _5 = "Fq";
var _6 = "Eq";
var _7 = "zq";
var _8 = "Bq";
var _9 = "Cq";
var _a = "B";
var _b = "EK";
export { _1 as "emphasizedText", _2 as "loadingProgressText", _3 as "loadingShimmer", _4 as "padding", _5 as "paddingBottom", _6 as "paddingTop", _7 as "placeholder", _8 as "placeholderInline", _9 as "progressLine", _a as "runningProgress", _b as "text" }
