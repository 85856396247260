import { TextSpan } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { FormGroup, InputField } from "@bokio/elements/Form";
import { Link } from "@bokio/elements/Link/Link";
import Markdown from "@bokio/elements/Markdown/Markdown";
import VatSettingAccountingTypeInput from "@bokio/guest/src/scenes/SignUp/components/SignUpCustomizeCompanySettings/components/VatSettingAccountingTypeInput/VatSettingAccountingTypeInput";
import { GeneralLangFactory, SettingsLangFactory, SignUpLangFactory } from "@bokio/lang";
import AccountingLangFactory from "@bokio/lang/AccountingLangFactory";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { FiscalYearTurnoverPicker } from "@bokio/settings/src/scenes/FiscalYears/components/FiscalYearTurnoverPicker";
import { FiscalYearValidator } from "@bokio/settings/src/scenes/FiscalYears/fiscalYearValidator";
import { getRoute } from "@bokio/shared/route";
import { noop } from "@bokio/shared/utils";
import { formatDate, formatMessage } from "@bokio/shared/utils/format";
import NP from "@bokio/utils/NP";

import type { FiscalYearFormData } from "@bokio/settings/src/scenes/FiscalYears/components/FiscalYearForm";

import * as styles from "../addFiscalYearModal.scss";

type CompanyInfo = m.Core.CompanyInfo;
import FiscalYearStatus = m.Core.FiscalYearStatus;
interface AddFiscalYearModalViewProps {
	formData: FiscalYearFormData;
	companyInfo: CompanyInfo;
	onUpdate: (data: FiscalYearFormData) => void;
	showSelectCompanyLink: boolean;
}

export const AddFiscalYearModalView = ({
	formData,
	companyInfo,
	onUpdate,
	showSelectCompanyLink,
}: AddFiscalYearModalViewProps) => {
	const generalLang = GeneralLangFactory();
	const accountingLang = AccountingLangFactory();
	const settingsLang = SettingsLangFactory();
	const signUpLang = SignUpLangFactory();
	const validator = new FiscalYearValidator(generalLang, accountingLang);
	const validation = validator.validate(formData);

	const shouldRenderTurnover = companyInfo.Country === m.CountryCode.SE;

	const description =
		companyInfo.FiscalYearStatus === FiscalYearStatus.PromptToCreateNextYear
			? settingsLang.AddFiscalYearModal_CreateUpcomming_Description
			: settingsLang.FiscalYears_NoActiveSettings_Message;
	return (
		<div className={styles.container} data-testid="AddFiscalYear_CreateView">
			<p className={styles.description}>{description}</p>
			<div className={styles.datesContainer}>
				<FormGroup className={styles.date}>
					<InputField
						disabled={true}
						label={generalLang.Startdate}
						value={NP(formData.StartDate, formatDate)}
						onChange={noop}
						errors={validator.filterByField("StartDate", validation.errors)}
					/>
				</FormGroup>
				<FormGroup className={styles.date}>
					<InputField
						disabled={true}
						label={generalLang.Enddate}
						value={NP(formData.EndDate, formatDate)}
						onChange={noop}
						errors={validator.filterByField("EndDate", validation.errors)}
					/>
				</FormGroup>
			</div>
			<p className={styles.inputInfo}>{settingsLang.FiscalYears_LockedDates_Explanation}</p>

			<VatSettingAccountingTypeInput
				company={companyInfo}
				state={{ accountingType: formData.AccountingForm, vatSetting: formData.VatSetting }}
				onStateChange={s => onUpdate({ ...formData, AccountingForm: s.accountingType, VatSetting: s.vatSetting })}
				vatSettingDescription={
					<TextSpan style="caption-default" color="ui-2">
						<Markdown inline noWrappingDiv markdownContent={settingsLang.FiscalYears_VatPeriod_Description} />
					</TextSpan>
				}
				accountingTypeDescription={
					<p className={styles.inputInfo}>
						{formatMessage(settingsLang.FiscalYears_AccountingMethod_Description, linkLabel => (
							<Link external={signUpLang.GovernmentCompanyInfoUrl} style="normal" target="_blank">
								{linkLabel}
							</Link>
						))}
					</p>
				}
				validation={validation}
				validator={validator}
			/>
			{shouldRenderTurnover && (
				<FormGroup>
					<FiscalYearTurnoverPicker
						turnover={formData.ExpectedTurnover}
						onChange={(val: number) => onUpdate({ ...formData, ExpectedTurnover: val })}
					/>
				</FormGroup>
			)}
			{showSelectCompanyLink && (
				<Link route={getRoute("selectCompany")}> {settingsLang.AddFiscalYearModal_SwitchCompanies} </Link>
			)}
		</div>
	);
};
