// extracted by css-extract-rspack-plugin
var _1 = "N7a";
var _2 = "Db";
var _3 = "O7a";
var _4 = "Hb";
var _5 = "P7a";
var _6 = "T7a";
var _7 = "M7a";
var _8 = "Q7a";
var _9 = "R7a";
var _a = "S7a";
var _b = "Tb";
export { _1 as "border", _2 as "colorError", _3 as "colorHighlight", _4 as "colorOk", _5 as "colorSubdued", _6 as "isAnimating", _7 as "root", _8 as "sizeNormal", _9 as "sizeSmall", _a as "sizeXsmall", _b as "zoomin" }
