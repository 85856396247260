// extracted by css-extract-rspack-plugin
var _1 = "iza";
var _2 = "S5";
var _3 = "L5";
var _4 = "O5";
var _5 = "N5";
var _6 = "M5";
var _7 = "jza";
var _8 = "kza";
var _9 = "lza";
var _a = "R5";
var _b = "Q5";
var _c = "P5";
export { _1 as "action", _2 as "backButton", _3 as "header", _4 as "headerWithPadding", _5 as "postTitle", _6 as "postTitleWrapper", _7 as "stackedActionGroup", _8 as "stackedPrimaryAction", _9 as "stackedSecondaryAction", _a as "title", _b as "titleRow", _c as "withPostTitle" }
