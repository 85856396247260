// extracted by css-extract-rspack-plugin
var _1 = "GA";
var _2 = "T_";
var _3 = "EA";
var _4 = "FA";
var _5 = "JA";
var _6 = "IA";
var _7 = "HA";
var _8 = "KA";
export { _1 as "active", _2 as "disabled", _3 as "input", _4 as "label", _5 as "labelNo", _6 as "labelYes", _7 as "medium", _8 as "small" }
