import { usePricePlanContext } from "@bokio/contexts/PricePlanContext/PricePlanContext";
import { usePricePlanFeatureContext } from "@bokio/contexts/PricePlanFeatureContext/PricePlanFeatureContext";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useRouter } from "@bokio/shared/containers/router/useRouter";
import { getRoute } from "@bokio/shared/route";
import { useCompanyUser } from "@bokio/shared/state/requests";

import BokioPlan = m.Entities.BokioPlan;
import PricePlanFeature = m.Bokio.Common.Contract.ViewData.PricePlanFeature;
export { BokioPlan, PricePlanFeature };

export interface BokioPlanLockBadgeHookProps {
	feature: PricePlanFeature;
}

export interface BokioPlanLockBadgeHookHandle {
	isFeatureLocked?: boolean;
	isInTrialPeriod?: boolean;
	daysUntilTrialEnded?: number;
	minimumPlan: BokioPlan;
	hasUpgradePermisson: boolean;
	/**
	 * Nullish when user don't have permission
	 */
	onCtaClick?: () => void;
}

/**
 * @returns The hook for rendering the correct price plan lock information. `undefined` if the feature shouldn't be blocked.
 */
export const useBokioPlanLockBadge = ({
	feature,
}: BokioPlanLockBadgeHookProps): BokioPlanLockBadgeHookHandle | undefined => {
	const router = useRouter();
	const { companyInfo } = useCompanyInfo();
	const { companyUserPermissions } = useCompanyUser();
	const { daysUntilTrialEnded } = usePricePlanContext();
	const { isPricePlanFeatureEnabled, isPricePlanFeatureInTrial, getLowestPlanNeededForPricePlanFeature } =
		usePricePlanFeatureContext();

	const isFeatureLocked = !isPricePlanFeatureEnabled(feature);
	const isInTrialPeriod = isPricePlanFeatureInTrial(feature);
	const minimumPlan = getLowestPlanNeededForPricePlanFeature(feature);

	const hasUpgradePermisson = !!companyUserPermissions?.CompanySettings;

	const onCtaClick = hasUpgradePermisson
		? () => {
				// TODO: Take minumum plan into consideration when we switch this with modal
				router.redirect(getRoute("billing", { company: companyInfo.Id }));
			}
		: undefined;

	const shouldRender = isFeatureLocked || isInTrialPeriod;

	return shouldRender
		? {
				isFeatureLocked,
				isInTrialPeriod,
				daysUntilTrialEnded,
				minimumPlan,
				hasUpgradePermisson,
				onCtaClick,
			}
		: undefined;
};
