import bankidSvg from "@bokio/assets/images/bankid.svg";
import { Div } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { GeneralLangFactory } from "@bokio/lang";

// Allowing only positive integers
type PositiveInt = `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}${number | ""}`;

interface BankIdLogoProps {
	size?: PositiveInt;
}

export const BankIdLogo: React.FC<BankIdLogoProps> = ({ size = "95" }) => {
	const generalLang = GeneralLangFactory();
	return (
		<Div>
			<img src={bankidSvg} style={{ height: `${size}px` }} alt={generalLang.BankID} />
		</Div>
	);
};
