// extracted by css-extract-rspack-plugin
var _1 = "S8a";
var _2 = "R8a";
var _3 = "H8a";
var _4 = "N8a";
var _5 = "G8a";
var _6 = "I8a";
var _7 = "J8a";
var _8 = "L8a";
var _9 = "M8a";
var _a = "K8a";
var _b = "T8a";
var _c = "O8a";
var _d = "Q8a";
var _e = "P8a";
var _f = "F8a";
var _10 = "U8a";
export { _1 as "blueBackground", _2 as "burgundyBackground", _3 as "closeButton", _4 as "closeIcon", _5 as "closeWrapper", _6 as "content", _7 as "contentExtraWide", _8 as "contentMedium", _9 as "contentSmall", _a as "contentWide", _b as "greenBackground", _c as "imageContainer", _d as "largeImage", _e as "mediumImage", _f as "modal", _10 as "whiteBackground" }
