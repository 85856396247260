import { BokioPlanFeatureButton } from "@bokio/components/BokioPlanFeatureButton/BokioPlanFeatureButton";
import { Button } from "@bokio/designsystem/components/Button/Button";
import { trackEvent } from "@bokio/utils/t";

import type { FontelloIcons } from "@bokio/assets/fontello";
import type { ButtonProps, ButtonPropsUnion } from "@bokio/designsystem/components/Button/Button";
import type { IconAlign } from "@bokio/designsystem/components/Button/button.types";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import type { ClickAction, RouteAction } from "@bokio/shared/utils/receiptButtonActions";
import type { TodoItemWithUpdates } from "../../utils/todoUtils";

import PricePlanFeature = m.Bokio.Common.Contract.ViewData.PricePlanFeature;

type ExternalAction = {
	kind: "external";
	external: string;
	target?: string;
};

type TrackingDetails = {
	section: m.Core.Contracts.Todo.TodoSectionType;
	group: m.Core.Contracts.Todo.TodoGroupType;
	providerKey: string;
};

export const translateToTrackingDetails = (item: TodoItemWithUpdates) => {
	const trackingDetails: TrackingDetails = {
		section: item.Section,
		group: item.Group,
		providerKey: item.ProviderKey,
	};
	return trackingDetails;
};

export type TodoPrimaryButtonProps = ((RouteAction | ClickAction | ExternalAction) & { text: string } & {
	disabled?: boolean;
	isLoading?: boolean;
	onClick?: () => void;
	icon?: FontelloIcons;
	iconAlign?: IconAlign;
	trackingInfo?: TrackingDetails;
}) & {
	/**
	 * When specified, the button will be switched to price plan lock button if the feature is not available for the price plan.
	 */
	feature?: PricePlanFeature;
};

export const TodoPrimaryButton = (props: TodoPrimaryButtonProps) => {
	const commonProps: ButtonProps = {
		size: "small",
		icon: props.icon,
		iconAlign: props.iconAlign,
		disabled: props.disabled,
		loading: props.isLoading,
	};

	let buttonPropsUnion: ButtonPropsUnion | null = null;

	const trackClick = (action: string) => {
		props.trackingInfo && trackEvent("Todo", action, props.text, undefined, props.trackingInfo);
	};

	const handleClick = (onClick: () => void) => {
		trackClick("Click");
		onClick();
	};

	switch (props.kind) {
		case "route":
			buttonPropsUnion = {
				...commonProps,
				type: "link",
				route: props.route,
				onNavigation: () => trackClick("Route"),
			};
			break;
		case "onClick":
			buttonPropsUnion = {
				...commonProps,
				disabled: props.disabled,
				onClick: () => handleClick(props.onClick),
			};
			break;
		case "external":
			buttonPropsUnion = {
				...commonProps,
				type: "link",
				route: props.external,
				target: props.target,
				external: true,
				onNavigation: () => trackClick("External_route"),
			};
			break;
		default:
			break;
	}

	if (!buttonPropsUnion) {
		return null;
	}

	return props.feature ? (
		<BokioPlanFeatureButton feature={props.feature} {...buttonPropsUnion}>
			{props.text}
		</BokioPlanFeatureButton>
	) : (
		<Button {...buttonPropsUnion}>{props.text}</Button>
	);
};
