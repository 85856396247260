// extracted by css-extract-rspack-plugin
var _1 = "cGa";
var _2 = "Ol";
var _3 = "ci";
var _4 = "Bl";
var _5 = "ai";
var _6 = "mva";
var _7 = "di";
var _8 = "Zk";
var _9 = "bi";
var _a = "ei";
var _b = "U";
var _c = "an";
var _d = "bGa";
var _e = "Ua";
var _f = "Va";
var _10 = "Zh";
var _11 = "zl";
var _12 = "N";
var _13 = "_h";
var _14 = "Dl";
var _15 = "Cl";
export { _1 as "alignSymbolWithActionButton", _2 as "clickable", _3 as "colorError", _4 as "colorGrey", _5 as "colorInfo", _6 as "colorPromotion", _7 as "colorSuccess", _8 as "colorTips", _9 as "colorWarning", _a as "colorWhite", _b as "content", _c as "contentAndButtonWrapper", _d as "contentGrid", _e as "iconCancel", _f as "iconCancelBox", _10 as "iconContainer", _11 as "notice", _12 as "symbol", _13 as "symbolCircle", _14 as "title", _15 as "titleRow" }
