// extracted by css-extract-rspack-plugin
var _1 = "cb";
var _2 = "qFa";
var _3 = "Ir";
var _4 = "Vy";
var _5 = "WL";
var _6 = "Wy";
var _7 = "Hr";
var _8 = "A";
var _9 = "rFa";
var _a = "Lr";
var _b = "Kr";
var _c = "Xy";
export { _1 as "blue", _2 as "center", _3 as "circle", _4 as "color", _5 as "dark", _6 as "green", _7 as "loader", _8 as "loaderAnimation", _9 as "message", _a as "paddingBottom", _b as "paddingTop", _c as "white" }
