import { AccountingLangFactory, BackOfficeLangFactory, CountriesLangFactory, GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";

import type { SelectFieldOption } from "@bokio/elements/Form/SelectField";
import type { AccountingLang } from "@bokio/lang/types/AccountingLang";
import type { CountriesLang } from "@bokio/lang/types/CountriesLang";

import VatSetting = m.Entities.VatSetting;
import CompanyType = m.Entities.CompanyType;
import AccountingType = m.Entities.AccountingType;
import CountryCode = m.CountryCode;
type InvoiceSenderData = m.Entities.InvoiceSenderData;
import InvoicePaymentMethodKey = m.Entities.Invoices.InvoicePaymentMethodKey;

export const getCompanyTypeLabel = (companyType: CompanyType, accountingLang: AccountingLang) => {
	const generalLang = GeneralLangFactory();
	switch (companyType) {
		case CompanyType.LimitedCompany:
			return accountingLang.Type_LimitedCompany;
		case CompanyType.Cooperative:
			return accountingLang.Type_Cooperative;
		case CompanyType.LimitedPartnership:
			return accountingLang.Type_LimitedPartnership;
		case CompanyType.Proprietorship:
			return accountingLang.Type_Proprietorship;
		case CompanyType.Partnership:
			return accountingLang.Type_Partnership;
		case CompanyType.NotSet:
			return generalLang.NotSet;
		default:
			return "";
	}
};

export const getCompanyTypeOptions = (supportedCompanyTypes: CompanyType[]): SelectFieldOption[] => {
	const accountingLang = AccountingLangFactory();
	const notSet = CompanyType.NotSet;
	return [notSet, ...supportedCompanyTypes].map(companyType => ({
		hidden: companyType === notSet,
		disabled: companyType === notSet,
		value: companyType,
		label: getCompanyTypeLabel(companyType, accountingLang),
	}));
};

export const getCompanyTypeOptionsWithNotSet = (supportedCompanyTypes: CompanyType[]): SelectFieldOption[] => {
	const accountingLang = AccountingLangFactory();
	const notSet = CompanyType.NotSet;
	return [notSet, ...supportedCompanyTypes].map(companyType => ({
		value: companyType,
		label: getCompanyTypeLabel(companyType, accountingLang),
	}));
};

export const getCountrySubdivisionLabel = (countrySubdivision: string, countriesLang: CountriesLang) => {
	switch (countrySubdivision) {
		case "NIR":
			return countriesLang.GB_NIR;
		case "ENG":
			return countriesLang.GB_ENG;
		case "SCT":
			return countriesLang.GB_SCT;
		case "WLS":
			return countriesLang.GB_WLS;
		default:
			return "";
	}
};

export const getCountryLabel = (countryIso3: string, countriesLang: CountriesLang) => {
	switch (countryIso3) {
		case "ABW":
			return countriesLang.ABW;
		case "AFG":
			return countriesLang.AFG;
		case "AGO":
			return countriesLang.AGO;
		case "AIA":
			return countriesLang.AIA;
		case "ALA":
			return countriesLang.ALA;
		case "ALB":
			return countriesLang.ALB;
		case "AND":
			return countriesLang.AND;
		case "ARE":
			return countriesLang.ARE;
		case "ARG":
			return countriesLang.ARG;
		case "ARM":
			return countriesLang.ARM;
		case "ASM":
			return countriesLang.ASM;
		case "ATA":
			return countriesLang.ATA;
		case "ATF":
			return countriesLang.ATF;
		case "ATG":
			return countriesLang.ATG;
		case "AUS":
			return countriesLang.AUS;
		case "AUT":
			return countriesLang.AUT;
		case "AZE":
			return countriesLang.AZE;
		case "BDI":
			return countriesLang.BDI;
		case "BEL":
			return countriesLang.BEL;
		case "BEN":
			return countriesLang.BEN;
		case "BES":
			return countriesLang.BES;
		case "BFA":
			return countriesLang.BFA;
		case "BGD":
			return countriesLang.BGD;
		case "BGR":
			return countriesLang.BGR;
		case "BHR":
			return countriesLang.BHR;
		case "BHS":
			return countriesLang.BHS;
		case "BIH":
			return countriesLang.BIH;
		case "BLM":
			return countriesLang.BLM;
		case "BLR":
			return countriesLang.BLR;
		case "BLZ":
			return countriesLang.BLZ;
		case "BMU":
			return countriesLang.BMU;
		case "BOL":
			return countriesLang.BOL;
		case "BRA":
			return countriesLang.BRA;
		case "BRB":
			return countriesLang.BRB;
		case "BRN":
			return countriesLang.BRN;
		case "BTN":
			return countriesLang.BTN;
		case "BVT":
			return countriesLang.BVT;
		case "BWA":
			return countriesLang.BWA;
		case "CAF":
			return countriesLang.CAF;
		case "CAN":
			return countriesLang.CAN;
		case "CCK":
			return countriesLang.CCK;
		case "CHE":
			return countriesLang.CHE;
		case "CHL":
			return countriesLang.CHL;
		case "CHN":
			return countriesLang.CHN;
		case "CIV":
			return countriesLang.CIV;
		case "CMR":
			return countriesLang.CMR;
		case "COD":
			return countriesLang.COD;
		case "COG":
			return countriesLang.COG;
		case "COK":
			return countriesLang.COK;
		case "COL":
			return countriesLang.COL;
		case "COM":
			return countriesLang.COM;
		case "CPV":
			return countriesLang.CPV;
		case "CRI":
			return countriesLang.CRI;
		case "CUB":
			return countriesLang.CUB;
		case "CUW":
			return countriesLang.CUW;
		case "CXR":
			return countriesLang.CXR;
		case "CYM":
			return countriesLang.CYM;
		case "CYP":
			return countriesLang.CYP;
		case "CZE":
			return countriesLang.CZE;
		case "DEU":
			return countriesLang.DEU;
		case "DJI":
			return countriesLang.DJI;
		case "DMA":
			return countriesLang.DMA;
		case "DNK":
			return countriesLang.DNK;
		case "DOM":
			return countriesLang.DOM;
		case "DZA":
			return countriesLang.DZA;
		case "ECU":
			return countriesLang.ECU;
		case "EGY":
			return countriesLang.EGY;
		case "ERI":
			return countriesLang.ERI;
		case "ESH":
			return countriesLang.ESH;
		case "ESP":
			return countriesLang.ESP;
		case "EST":
			return countriesLang.EST;
		case "ETH":
			return countriesLang.ETH;
		case "FIN":
			return countriesLang.FIN;
		case "FJI":
			return countriesLang.FJI;
		case "FLK":
			return countriesLang.FLK;
		case "FRA":
			return countriesLang.FRA;
		case "FRO":
			return countriesLang.FRO;
		case "FSM":
			return countriesLang.FSM;
		case "GAB":
			return countriesLang.GAB;
		case "GBR":
			return countriesLang.GBR;
		case "GEO":
			return countriesLang.GEO;
		case "GGY":
			return countriesLang.GGY;
		case "GHA":
			return countriesLang.GHA;
		case "GIB":
			return countriesLang.GIB;
		case "GIN":
			return countriesLang.GIN;
		case "GLP":
			return countriesLang.GLP;
		case "GMB":
			return countriesLang.GMB;
		case "GNB":
			return countriesLang.GNB;
		case "GNQ":
			return countriesLang.GNQ;
		case "GRC":
			return countriesLang.GRC;
		case "GRD":
			return countriesLang.GRD;
		case "GRL":
			return countriesLang.GRL;
		case "GTM":
			return countriesLang.GTM;
		case "GUF":
			return countriesLang.GUF;
		case "GUM":
			return countriesLang.GUM;
		case "GUY":
			return countriesLang.GUY;
		case "HKG":
			return countriesLang.HKG;
		case "HMD":
			return countriesLang.HMD;
		case "HND":
			return countriesLang.HND;
		case "HRV":
			return countriesLang.HRV;
		case "HTI":
			return countriesLang.HTI;
		case "HUN":
			return countriesLang.HUN;
		case "IDN":
			return countriesLang.IDN;
		case "IMN":
			return countriesLang.IMN;
		case "IND":
			return countriesLang.IND;
		case "IOT":
			return countriesLang.IOT;
		case "IRL":
			return countriesLang.IRL;
		case "IRN":
			return countriesLang.IRN;
		case "IRQ":
			return countriesLang.IRQ;
		case "ISL":
			return countriesLang.ISL;
		case "ISR":
			return countriesLang.ISR;
		case "ITA":
			return countriesLang.ITA;
		case "JAM":
			return countriesLang.JAM;
		case "JEY":
			return countriesLang.JEY;
		case "JOR":
			return countriesLang.JOR;
		case "JPN":
			return countriesLang.JPN;
		case "KAZ":
			return countriesLang.KAZ;
		case "KEN":
			return countriesLang.KEN;
		case "KGZ":
			return countriesLang.KGZ;
		case "KHM":
			return countriesLang.KHM;
		case "KIR":
			return countriesLang.KIR;
		case "KNA":
			return countriesLang.KNA;
		case "KOR":
			return countriesLang.KOR;
		case "KWT":
			return countriesLang.KWT;
		case "LAO":
			return countriesLang.LAO;
		case "LBN":
			return countriesLang.LBN;
		case "LBR":
			return countriesLang.LBR;
		case "LBY":
			return countriesLang.LBY;
		case "LCA":
			return countriesLang.LCA;
		case "LIE":
			return countriesLang.LIE;
		case "LKA":
			return countriesLang.LKA;
		case "LSO":
			return countriesLang.LSO;
		case "LTU":
			return countriesLang.LTU;
		case "LUX":
			return countriesLang.LUX;
		case "LVA":
			return countriesLang.LVA;
		case "MAC":
			return countriesLang.MAC;
		case "MAF":
			return countriesLang.MAF;
		case "MAR":
			return countriesLang.MAR;
		case "MCO":
			return countriesLang.MCO;
		case "MDA":
			return countriesLang.MDA;
		case "MDG":
			return countriesLang.MDG;
		case "MDV":
			return countriesLang.MDV;
		case "MEX":
			return countriesLang.MEX;
		case "MHL":
			return countriesLang.MHL;
		case "MKD":
			return countriesLang.MKD;
		case "MLI":
			return countriesLang.MLI;
		case "MLT":
			return countriesLang.MLT;
		case "MMR":
			return countriesLang.MMR;
		case "MNE":
			return countriesLang.MNE;
		case "MNG":
			return countriesLang.MNG;
		case "MNP":
			return countriesLang.MNP;
		case "MOZ":
			return countriesLang.MOZ;
		case "MRT":
			return countriesLang.MRT;
		case "MSR":
			return countriesLang.MSR;
		case "MTQ":
			return countriesLang.MTQ;
		case "MUS":
			return countriesLang.MUS;
		case "MWI":
			return countriesLang.MWI;
		case "MYS":
			return countriesLang.MYS;
		case "MYT":
			return countriesLang.MYT;
		case "NAM":
			return countriesLang.NAM;
		case "NCL":
			return countriesLang.NCL;
		case "NER":
			return countriesLang.NER;
		case "NFK":
			return countriesLang.NFK;
		case "NGA":
			return countriesLang.NGA;
		case "NIC":
			return countriesLang.NIC;
		case "NIU":
			return countriesLang.NIU;
		case "NLD":
			return countriesLang.NLD;
		case "NOR":
			return countriesLang.NOR;
		case "NPL":
			return countriesLang.NPL;
		case "NRU":
			return countriesLang.NRU;
		case "NZL":
			return countriesLang.NZL;
		case "OMN":
			return countriesLang.OMN;
		case "PAK":
			return countriesLang.PAK;
		case "PAN":
			return countriesLang.PAN;
		case "PCN":
			return countriesLang.PCN;
		case "PER":
			return countriesLang.PER;
		case "PHL":
			return countriesLang.PHL;
		case "PLW":
			return countriesLang.PLW;
		case "PNG":
			return countriesLang.PNG;
		case "POL":
			return countriesLang.POL;
		case "PRI":
			return countriesLang.PRI;
		case "PRK":
			return countriesLang.PRK;
		case "PRT":
			return countriesLang.PRT;
		case "PRY":
			return countriesLang.PRY;
		case "PSE":
			return countriesLang.PSE;
		case "PYF":
			return countriesLang.PYF;
		case "QAT":
			return countriesLang.QAT;
		case "REU":
			return countriesLang.REU;
		case "ROU":
			return countriesLang.ROU;
		case "RUS":
			return countriesLang.RUS;
		case "RWA":
			return countriesLang.RWA;
		case "SAU":
			return countriesLang.SAU;
		case "SDN":
			return countriesLang.SDN;
		case "SEN":
			return countriesLang.SEN;
		case "SGP":
			return countriesLang.SGP;
		case "SGS":
			return countriesLang.SGS;
		case "SHN":
			return countriesLang.SHN;
		case "SJM":
			return countriesLang.SJM;
		case "SLB":
			return countriesLang.SLB;
		case "SLE":
			return countriesLang.SLE;
		case "SLV":
			return countriesLang.SLV;
		case "SMR":
			return countriesLang.SMR;
		case "SOM":
			return countriesLang.SOM;
		case "SPM":
			return countriesLang.SPM;
		case "SRB":
			return countriesLang.SRB;
		case "SSD":
			return countriesLang.SSD;
		case "STP":
			return countriesLang.STP;
		case "SUR":
			return countriesLang.SUR;
		case "SVK":
			return countriesLang.SVK;
		case "SVN":
			return countriesLang.SVN;
		case "SWE":
			return countriesLang.SWE;
		case "SWZ":
			return countriesLang.SWZ;
		case "SXM":
			return countriesLang.SXM;
		case "SYC":
			return countriesLang.SYC;
		case "SYR":
			return countriesLang.SYR;
		case "TCA":
			return countriesLang.TCA;
		case "TCD":
			return countriesLang.TCD;
		case "TGO":
			return countriesLang.TGO;
		case "THA":
			return countriesLang.THA;
		case "TJK":
			return countriesLang.TJK;
		case "TKL":
			return countriesLang.TKL;
		case "TKM":
			return countriesLang.TKM;
		case "TLS":
			return countriesLang.TLS;
		case "TON":
			return countriesLang.TON;
		case "TTO":
			return countriesLang.TTO;
		case "TUN":
			return countriesLang.TUN;
		case "TUR":
			return countriesLang.TUR;
		case "TUV":
			return countriesLang.TUV;
		case "TWN":
			return countriesLang.TWN;
		case "TZA":
			return countriesLang.TZA;
		case "UGA":
			return countriesLang.UGA;
		case "UKR":
			return countriesLang.UKR;
		case "UMI":
			return countriesLang.UMI;
		case "URY":
			return countriesLang.URY;
		case "USA":
			return countriesLang.USA;
		case "UZB":
			return countriesLang.UZB;
		case "VAT":
			return countriesLang.VAT;
		case "VCT":
			return countriesLang.VCT;
		case "VEN":
			return countriesLang.VEN;
		case "VGB":
			return countriesLang.VGB;
		case "VIR":
			return countriesLang.VIR;
		case "VNM":
			return countriesLang.VNM;
		case "VUT":
			return countriesLang.VUT;
		case "WLF":
			return countriesLang.WLF;
		case "WSM":
			return countriesLang.WSM;
		case "XKX":
			return countriesLang.XKX;
		case "YEM":
			return countriesLang.YEM;
		case "ZAF":
			return countriesLang.ZAF;
		case "ZMB":
			return countriesLang.ZMB;
		case "ZWE":
			return countriesLang.ZWE;
		default:
			return "";
	}
};

export const getCountrySubdivisionOptions = (supportedCountrySubdivisions: string[]): SelectFieldOption[] => {
	const countriesLang = CountriesLangFactory();
	const notSet = CompanyType.NotSet;
	return [notSet, ...supportedCountrySubdivisions].map(countrySubdivision => ({
		hidden: countrySubdivision === notSet,
		disabled: countrySubdivision === notSet,
		value: countrySubdivision,
		label: getCountrySubdivisionLabel(countrySubdivision, countriesLang),
	}));
};

export const getAccountingTypeLabel = (
	accountingType: AccountingType,
	country: CountryCode,
	companyType: CompanyType,
	vatSetting: VatSetting,
): string => {
	const accountingLang = AccountingLangFactory();

	if (country === CountryCode.GB) {
		if (companyType !== CompanyType.LimitedCompany && vatSetting === VatSetting.ExternalVatProvider) {
			switch (accountingType) {
				case AccountingType.Invoice:
					return accountingLang.AccountingType_SoleTraderVatRegistered_Invoice;
				case AccountingType.Cash:
					return accountingLang.AccountingType_SoleTraderVatRegistered_Cash;
				case AccountingType.InvoiceWithCashVAT:
					return accountingLang.AccountingType_SoleTraderVatRegistered_InvoiceWithCashVAT;
				case AccountingType.NotSet:
					return GeneralLangFactory().NotSet;
			}
		} else if (companyType !== CompanyType.LimitedCompany && vatSetting === VatSetting.NotRegisteredForVat) {
			switch (accountingType) {
				case AccountingType.Invoice:
					return accountingLang.AccountingType_SoleTraderNotVatRegistered_Invoice;
				case AccountingType.Cash:
					return accountingLang.AccountingType_SoleTraderNotVatRegistered_Cash;
				case AccountingType.NotSet:
					return GeneralLangFactory().NotSet;
			}
		}
	}

	switch (accountingType) {
		case AccountingType.Invoice:
			return accountingLang.AccountingType_Invoice;
		case AccountingType.Cash:
			return accountingLang.AccountingType_Cash;
		case AccountingType.InvoiceWithCashVAT:
			return accountingLang.AccountingType_InvoiceWithCashVAT;
		case AccountingType.NotSet:
			return GeneralLangFactory().NotSet;
	}
};

export const getDefaultAccountingTypeLabel = (accountingType: AccountingType) => {
	const accountingLang = AccountingLangFactory();
	switch (accountingType) {
		case AccountingType.Invoice:
			return accountingLang.AccountingType_Invoice;
		case AccountingType.Cash:
			return accountingLang.AccountingType_Cash;
		case AccountingType.InvoiceWithCashVAT:
			return accountingLang.AccountingType_InvoiceWithCashVAT;
		case AccountingType.NotSet:
			return GeneralLangFactory().NotSet;
	}
};

const getAccountingOptionsGivenPermittedTypes = (
	permittedTypes: AccountingType[],
	country: CountryCode,
	companyType: CompanyType,
	vatSetting: VatSetting,
): SelectFieldOption[] => {
	const notSet = AccountingType.NotSet;
	return [notSet, ...permittedTypes].map(accountingType => ({
		hidden: accountingType === notSet,
		disabled: accountingType === notSet,
		value: accountingType,
		label: accountingType !== notSet ? getAccountingTypeLabel(accountingType, country, companyType, vatSetting) : "",
	}));
};

export const getRuleByCompanyTypeAndVatSetting = (
	companyType: CompanyType,
	vatSetting: VatSetting,
	rulesList: m.Classes.RulesByCompanyTypeAndVatSetting[],
): m.Classes.RulesByCompanyTypeAndVatSetting | undefined => {
	return rulesList.find(
		r => r.AppliesToCompanyType.includes(companyType) && r.AppliesToVatSetting.includes(vatSetting),
	);
};

export const getPermittedAccountingTypes = (
	companyType: CompanyType,
	vatSetting: VatSetting,
	rulesList: m.Classes.RulesByCompanyTypeAndVatSetting[],
): AccountingType[] => {
	if (companyType === CompanyType.NotSet || vatSetting === VatSetting.NotSet) {
		return [AccountingType.Invoice, AccountingType.Cash]; // permissive
	}
	const rules = getRuleByCompanyTypeAndVatSetting(companyType, vatSetting, rulesList);
	if (!rules) {
		return [];
	}
	return rules.PermittedAccountingTypes;
};

export const getAccountingOptions = (
	country: CountryCode,
	companyType: CompanyType,
	vatSetting: VatSetting,
	rulesList: m.Classes.RulesByCompanyTypeAndVatSetting[],
): SelectFieldOption[] => {
	const permitted = getPermittedAccountingTypes(companyType, vatSetting, rulesList);
	return getAccountingOptionsGivenPermittedTypes(permitted, country, companyType, vatSetting);
};

export const getRuleByCompanyType = (
	companyType: CompanyType,
	rulesList: m.Classes.RulesByCompanyType[],
): m.Classes.RulesByCompanyType | undefined => {
	return rulesList.find(r => r.AppliesToCompanyType.includes(companyType));
};

export const makeAccountingTypeCompliant = (
	current: AccountingType,
	companyType: CompanyType,
	vatSetting: VatSetting,
	rulesList: m.Classes.RulesByCompanyTypeAndVatSetting[],
): AccountingType => {
	const permitted = getPermittedAccountingTypes(companyType, vatSetting, rulesList);
	if (current !== AccountingType.NotSet && !permitted.includes(current)) {
		return permitted[0];
	}
	return current;
};

export type VatSettingLabelMode = "default" | "short";
export const getVatSettingLabel = (setting: m.Entities.VatSetting, mode: VatSettingLabelMode = "default") => {
	const generalLang = GeneralLangFactory();

	switch (setting) {
		case m.Entities.VatSetting.ExternalVatProvider:
			return generalLang.VatSetting_ExternalVatProvider;
		case m.Entities.VatSetting.NotRegisteredForVat:
			return generalLang.VatSetting_NotRegisteredForVat;
		case m.Entities.VatSetting.Monthly:
			return mode === "short" ? generalLang.VatSetting_Monthly_Short : generalLang.VatSetting_Monthly;
		case m.Entities.VatSetting.Quarterly:
			return mode === "short" ? generalLang.VatSetting_Quarterly_Short : generalLang.VatSetting_Quarterly;
		case m.Entities.VatSetting.Yearly:
			return mode === "short" ? generalLang.VatSetting_Yearly_Short : generalLang.VatSetting_Yearly;
		default:
			return generalLang.NotSet;
	}
};

export const getVATReportingPeriodOptions = (
	periods: VatSetting[],
	mode: VatSettingLabelMode = "default",
): SelectFieldOption[] => {
	const notSet = VatSetting.NotSet;

	return [notSet, ...periods].map(vatSetting => ({
		hidden: vatSetting === notSet,
		disabled: vatSetting === notSet,
		value: vatSetting,
		label: vatSetting !== notSet ? getVatSettingLabel(vatSetting, mode) : "",
	}));
};

export const getVatSettingStatusTitle = (setting: m.Entities.VatSetting) => {
	const backOfficeLang = BackOfficeLangFactory();
	switch (setting) {
		case VatSetting.ExternalVatProvider:
		case VatSetting.NotRegisteredForVat:
		case VatSetting.NotSet:
			return backOfficeLang.VatStatus_NonPeriodic;
		default:
			return backOfficeLang.VatStatus_Periodic;
	}
};

export const getVatSettingTitle = () => {
	const accountingLang = AccountingLangFactory();
	return accountingLang.WhichVatPeriodDoYouUse;
};

export function isCompanyNotSet(company: m.Core.CompanyInfo) {
	return company.CompanyType === "NotSet" || company.AccountingMethodNotSet || company.VatSettingMissing;
}

export function isBokioPaymentMethod(senderData: InvoiceSenderData, isforeignCustomer?: boolean) {
	return (
		(!isforeignCustomer && senderData.PaymentMethod.Key === InvoicePaymentMethodKey.Bokio_Bankgiro) ||
		senderData.ForeignPaymentMethod.Key === InvoicePaymentMethodKey.Bokio_Bankgiro
	);
}

export const isTestCompany = (company: m.Core.CompanyInfo) => {
	return (
		company.CompanySystem === m.Entities.CompanySystem.DemoCompany ||
		company.CompanySystem === m.Entities.CompanySystem.TestCompany
	);
};
