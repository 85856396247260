// extracted by css-extract-rspack-plugin
var _1 = "Uf";
var _2 = "pCa";
var _3 = "sCa";
var _4 = "tCa";
var _5 = "E7";
var _6 = "gCa";
var _7 = "F7";
var _8 = "bEa";
var _9 = "_Fa";
var _a = "QEa";
var _b = "aEa";
var _c = "ok";
var _d = "ak";
var _e = "n2";
var _f = "Hk";
var _10 = "Qk";
var _11 = "el";
var _12 = "Kl";
var _13 = "gl";
var _14 = "IUa";
var _15 = "ol";
var _16 = "Fl";
var _17 = "D7";
var _18 = "Fk";
var _19 = "lk";
var _1a = "rX";
var _1b = "Gk";
var _1c = "Pk";
var _1d = "Rk";
var _1e = "Il";
var _1f = "fl";
var _20 = "HUa";
var _21 = "ll";
var _22 = "sl";
var _23 = "C7";
var _24 = "Ek";
var _25 = "op";
var _26 = "qp";
var _27 = "aGa";
var _28 = "k8";
var _29 = "EL";
export { _1 as "centerHeading", _2 as "fontBodyBold", _3 as "fontBodyDefault", _4 as "fontBodyLargeDefault", _5 as "fontCaptionBold", _6 as "fontCaptionDefault", _7 as "fontCompanyListHeading", _8 as "fontHeadingDefault", _9 as "fontLargeHeadingBold", _a as "fontLargeHeadingDefault", _b as "fontSubheadingDefault", _c as "headingHElement", _d as "headingWrapper", _e as "headingWrapperMarginAway_0", _f as "headingWrapperMarginAway_12", _10 as "headingWrapperMarginAway_16", _11 as "headingWrapperMarginAway_24", _12 as "headingWrapperMarginAway_24t16m", _13 as "headingWrapperMarginAway_32", _14 as "headingWrapperMarginAway_4", _15 as "headingWrapperMarginAway_40", _16 as "headingWrapperMarginAway_48", _17 as "headingWrapperMarginAway_6", _18 as "headingWrapperMarginAway_8", _19 as "sectionWrapper", _1a as "sectionWrapperMarginAway_0", _1b as "sectionWrapperMarginAway_12", _1c as "sectionWrapperMarginAway_16", _1d as "sectionWrapperMarginAway_24", _1e as "sectionWrapperMarginAway_24t16m", _1f as "sectionWrapperMarginAway_32", _20 as "sectionWrapperMarginAway_4", _21 as "sectionWrapperMarginAway_40", _22 as "sectionWrapperMarginAway_48", _23 as "sectionWrapperMarginAway_6", _24 as "sectionWrapperMarginAway_8", _25 as "strong", _26 as "textCenter", _27 as "useVariableColor", _28 as "useVariableMarginBottom", _29 as "whiteSpacePreLine" }
