import { useHelpContext } from "@bokio/contexts/HelpContext/HelpContext";
import { Link } from "@bokio/elements/Link/Link";
import { britishCountryIso2, getCurrentCountryIso, swedishCountryIso2 } from "@bokio/shared/utils/culture";

import { HelpWindowMode } from "../Help/helpSupportUtils";

import type * as React from "react";

export const helpArticleAliases = [
	"ConnectBokioBusinessAccountToBookkeepingAccount",
	"FBEHelp",
	"UpgradePlaidBankFeedConnection",
	"BanksAvailableForBankFeedConnection",
	"BBACard",
	"BBAGetStarted",
	"AutomaticBankConnection",
	"CreateBankRules",
	"BokioBusinesAccountGetStarted",
	"SetOpeningBalances",
	"Tags",
	"SocialFeesDiscount",
	"NewSalaryFlow",
	"NewVatFlow",
	"BBAFaq",
	"SwishFaq",
	"InternationalPayments",
	"BrowserSupport",
	"InternationalPaymentsV2",
] as const;
export type HelpArticleAlias = (typeof helpArticleAliases)[number];

export interface HelpLinkProps {
	helpArticle: HelpArticleAlias;
	children?: React.ReactNode;
	testId?: string;
	onClick?: () => void;
	openExternally?: boolean;
}

// To find the guid for an Umbraco help article. Go to https://www.bokio.se or https://www.bokio.co.uk.
// Find the right help article, right click, view source and pick the guid from <meta name="id" content="<guid>" />
type HelpArticleAliasMap = Record<HelpArticleAlias, string | undefined>;
const helpArticleAliasMapSe: HelpArticleAliasMap = {
	ConnectBokioBusinessAccountToBookkeepingAccount: "559a8ff4-9e66-4ae2-a4d7-d5c6af1e5895",
	FBEHelp: "1c3649b3-a434-4799-bc1d-883c2d912ac4",
	UpgradePlaidBankFeedConnection: undefined,
	BanksAvailableForBankFeedConnection: "8842098c-b65e-4fbd-b517-17385fe55737",
	BBACard: "a9c0a1a1-cd87-4161-bbb1-e1062819ca15",
	BBAGetStarted: "007a3038-4011-40cd-afaf-f83a72b8a5c1",
	AutomaticBankConnection: "8842098c-b65e-4fbd-b517-17385fe55737",
	CreateBankRules: "5c2faebc-2b82-471e-be10-258e3d2e75b7",
	BokioBusinesAccountGetStarted: "007a3038-4011-40cd-afaf-f83a72b8a5c1",
	SetOpeningBalances: "f40331b9-6fce-4312-bc9c-339167a2a5a7",
	Tags: "519dda82-accc-4cc7-8de4-dc02a57687e9",
	SocialFeesDiscount: "1c4922e3-cf69-4261-8510-775c0c3debdd",
	NewSalaryFlow: "88a4b1e7-95d1-49a8-bce2-a09afca6c158",
	NewVatFlow: "224b4807-d5a2-40d6-9921-0e2924931d4c",
	BBAFaq: "a72278ef-67eb-49cc-9583-8ade7c98c479",
	SwishFaq: "2c4571cb-77f2-4063-b1e9-9c6c7948541e",
	InternationalPayments: "50625d97-4adf-4661-8fd7-5b8ae1821de6",
	InternationalPaymentsV2: "ef7b7ef6-33ff-455d-a26a-1bb024ceca98",
	BrowserSupport: "7e4eeed9-ac06-4a33-957e-1715ce0da351",
};

const helpArticleAliasMapGb: HelpArticleAliasMap = {
	ConnectBokioBusinessAccountToBookkeepingAccount: "3d6dbc77-7417-4409-bebd-08dd9b98c4bf",
	FBEHelp: "3e810067-29b8-4e16-b30d-3801971b59af",
	UpgradePlaidBankFeedConnection: "d8e280fc-baab-4890-a22d-50241d276837",
	BanksAvailableForBankFeedConnection: undefined,
	BBAGetStarted: "",
	AutomaticBankConnection: "",
	CreateBankRules: "73b48690-e0f8-4814-b4da-d5ecef53f32d",
	BBACard: undefined,
	BokioBusinesAccountGetStarted: undefined,
	SetOpeningBalances: "af33958b-8caa-457c-b09e-c7d13619469b",
	Tags: "d18387b2-ff1a-4fd5-92ea-1724f1f66cf0",
	SocialFeesDiscount: undefined,
	NewSalaryFlow: undefined,
	NewVatFlow: undefined,
	BBAFaq: undefined,
	SwishFaq: undefined,
	InternationalPayments: undefined,
	InternationalPaymentsV2: undefined,
	BrowserSupport: "8a8144e0-aad7-4e8b-bc04-dfc11f074c38",
};

export const helpArticleid = (helpArticle: HelpArticleAlias) => {
	switch (getCurrentCountryIso()) {
		case swedishCountryIso2:
			return helpArticleAliasMapSe[helpArticle];
		case britishCountryIso2:
			return helpArticleAliasMapGb[helpArticle];
		default:
			return undefined;
	}
};

export const HelpLink: React.FC<HelpLinkProps> = ({ children, helpArticle, testId, onClick, openExternally }) => {
	const { openHelpArticle, show, onNavigate, getCachedHelpPageModel } = useHelpContext();
	const id = helpArticleid(helpArticle);

	return id ? (
		<Link
			target="_blank"
			// Try to load proper external URL when available for better accessibility.
			external={getCachedHelpPageModel(id)?.Url}
			onClick={
				openExternally
					? undefined
					: event => {
							event.preventDefault();
							show();
							onNavigate(HelpWindowMode.Article);
							openHelpArticle(id);
							onClick && onClick();
						}
			}
			testId={testId}
		>
			{children}
		</Link>
	) : null;
};
