import { returnedFromBankIdHash } from "@bokio/hooks/useReturnedFromBankId/useReturnedFromBankId";

export type OsChecks = {
	isIOS?: boolean;
	isChromeOnIOs?: boolean;
	isFirefoxOnIOs?: boolean;
};

export const getAutostartUrl = (bankIdAutoStartToken: string, osChecks: OsChecks) => {
	const osChecksDefaults: OsChecks = {
		isIOS: false,
		isChromeOnIOs: false,
		isFirefoxOnIOs: false,
	};
	const checks = { ...osChecksDefaults, ...osChecks };

	if (!checks.isIOS) {
		return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=null`;
	}

	if (checks.isChromeOnIOs) {
		return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=${encodeURIComponent("googlechromes://")}`;
	}

	if (checks.isFirefoxOnIOs) {
		return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=${encodeURIComponent("firefox://")}`;
	}

	//Safari on IOS deallocates the tab and restarts it when going from and to the BankID app.
	//Thats why we need to signal to ourselves that we're coming back with the query param so that we can start polling again
	const redirect = `${encodeURIComponent(location.href.replace(returnedFromBankIdHash, ""))}${returnedFromBankIdHash}`; //Replace if we already have one in the URL to prevent weird state
	return `bankid:///?autostarttoken=${bankIdAutoStartToken}&redirect=${redirect}`;
};
