// extracted by css-extract-rspack-plugin
var _1 = "u3a";
var _2 = "pt";
var _3 = "at";
var _4 = "nt";
var _5 = "ot";
var _6 = "_s";
var _7 = "t3a";
var _8 = "s3a";
var _9 = "qt";
var _a = "jt";
export { _1 as "arrow", _2 as "black", _3 as "heading", _4 as "paddingOff", _5 as "paddingOn", _6 as "tooltip", _7 as "tooltipContent", _8 as "tooltipTrigger", _9 as "white", _a as "wrapper" }
