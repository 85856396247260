// extracted by css-extract-rspack-plugin
var _1 = "VUa";
var _2 = "fVa";
var _3 = "oVa";
var _4 = "ZUa";
var _5 = "XUa";
var _6 = "YUa XUa";
var _7 = "UUa";
var _8 = "WUa";
var _9 = "iVa";
var _a = "kVa";
var _b = "jVa";
var _c = "SUa";
var _d = "gVa";
var _e = "eVa nVa";
var _f = "lVa";
var _10 = "nVa";
var _11 = "mVa";
var _12 = "cVa nVa";
var _13 = "dVa";
export { _1 as "bankIdLoading", _2 as "bold", _3 as "emptyView", _4 as "invisbleBorderLeft", _5 as "invisibleWidthIconPlaceholder", _6 as "invisibleWidthPlaceholder", _7 as "mainTableWrapper", _8 as "margin0", _9 as "row", _a as "rowDesc", _b as "rowTitle", _c as "sectionHeader", _d as "submitButton", _e as "sum", _f as "sumTr", _10 as "td", _11 as "thead", _12 as "total", _13 as "totalDescription" }
