// extracted by css-extract-rspack-plugin
var _1 = "z8";
var _2 = "h0";
var _3 = "u9";
var _4 = "VZ";
var _5 = "UZ";
var _6 = "y8";
var _7 = "Dqa";
var _8 = "Aj";
var _9 = "XZ";
var _a = "QZ";
var _b = "TZ";
var _c = "w8";
var _d = "Cqa";
var _e = "zj";
var _f = "yj";
var _10 = "WZ";
var _11 = "s9";
var _12 = "t9";
var _13 = "RZ";
var _14 = "x8";
var _15 = "SZ";
var _16 = "Eqa";
export { _1 as "active", _2 as "activeWrapper", _3 as "disabled", _4 as "docImg", _5 as "docImgWrapper", _6 as "dropzone", _7 as "dropzoneDefaultSize", _8 as "fullscreen", _9 as "icon", _a as "loading", _b as "logo", _c as "mainText", _d as "noMargin", _e as "noMarginBottom", _f as "noMarginTop", _10 as "p", _11 as "rejected", _12 as "small", _13 as "smallLoading", _14 as "subText", _15 as "text", _16 as "transparentButton" }
