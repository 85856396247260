// extracted by css-extract-rspack-plugin
var _1 = "Uka";
var _2 = "_ka";
var _3 = "bxa";
var _4 = "axa";
var _5 = "pwa";
var _6 = "m8";
var _7 = "l8";
var _8 = "jda";
var _9 = "qd";
var _a = "cxa";
var _b = "Pna";
var _c = "Gna";
var _d = "wsa";
var _e = "Xza";
var _f = "EBa";
var _10 = "xBa";
var _11 = "Cca";
var _12 = "Yza";
var _13 = "xza";
var _14 = "vca";
var _15 = "Pva";
var _16 = "Aoa";
var _17 = "yza";
var _18 = "fCa";
var _19 = "r_";
var _1a = "oca";
var _1b = "DBa";
export { _1 as "active", _2 as "activeDark", _3 as "amend", _4 as "archived", _5 as "attention", _6 as "base", _7 as "campaign", _8 as "clickable", _9 as "clickableIcon", _a as "complete", _b as "credit", _c as "draft", _d as "errorLight", _e as "ghost", _f as "leftIcon", _10 as "legend", _11 as "legendStatus", _12 as "link", _13 as "locked", _14 as "noMargin", _15 as "overdue", _16 as "rejected", _17 as "removable", _18 as "rightIcon", _19 as "status", _1a as "statusNumber", _1b as "text" }
