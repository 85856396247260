// extracted by css-extract-rspack-plugin
var _1 = "nFa";
var _2 = "lFa";
var _3 = "mFa";
var _4 = "pFa";
var _5 = "oFa";
var _6 = "kFa";
var _7 = "jFa";
var _8 = "HY";
var _9 = "GY";
var _a = "sY";
var _b = "RY HY";
var _c = "Nk";
var _d = "IY HY";
var _e = "Lk";
var _f = "RCa";
var _10 = "SY HY";
var _11 = "Ok";
var _12 = "JY HY";
var _13 = "Mk";
var _14 = "EFa";
var _15 = "TCa";
var _16 = "iFa";
export { _1 as "arrow", _2 as "arrowColorDefault", _3 as "arrowColorNotice", _4 as "arrowDown", _5 as "arrowUp", _6 as "borderRadiusMedium", _7 as "borderRadiusSmall", _8 as "popover", _9 as "popoverDirectionDown", _a as "popoverDirectionUp", _b as "popoverInDown", _c as "popoverInDownAnimation", _d as "popoverInUp", _e as "popoverInUpAnimation", _f as "popoverMinWidth", _10 as "popoverOutDown", _11 as "popoverOutDownAnimation", _12 as "popoverOutUp", _13 as "popoverOutUpAnimation", _14 as "stretch", _15 as "variantDefault", _16 as "variantNotice" }
